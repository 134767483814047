<template>
  <ANZINPUTFIELD :data="data" :update-node-key="updateNodeKey" :type="'number'"></ANZINPUTFIELD>
</template>

<script>
import ANZINPUTFIELD from './ANZINPUTFIELD';

export default {
  name: 'ANZINPUTFIELDNUM',

  components: {
    ANZINPUTFIELD
  },

  props: {
    data: {
      type: Object,
      required: true
    },

    updateNodeKey: {
      type: String,
      required: false
    }
  }
};
</script>
